import { useField, useForm } from "vee-validate";
import { object, string } from "yup";
import { SearchProduct } from "~/types/api/search.types";

export const useSearch = () => {
  const { emit } = getCurrentInstance() || {};
  const { t } = useI18n();
  const { integrationCallbackStatuses, gaDynamicParamsEvent } =
    useGoogleAnalyticsEvents();

  const { searchEvent } = useEvents();
  const { $api } = useNuxtApp();

  const count = 3;
  const loading = ref(false);
  const listData = ref<SearchProduct[] | []>([]);

  let timeout: ReturnType<typeof setTimeout> | null = null;

  const schema = object().shape({
    search: string().max(128, t("maximum128CharactersAllowed")),
  });

  const { handleSubmit } = useForm({
    validationSchema: schema,
  });

  const { value: search, errorMessage } = useField<string>("search");

  const requestParams = computed(() => ({
    query: search.value,
  }));

  watch(requestParams, (val) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    if (typeof emit === "function") {
      emit("setSearchLength", val.query);
    }

    if (val && val.query) {
      loading.value = true;
      timeout = setTimeout(async () => {
        await getResult();
        searchEvent(val.query);
      }, 400);
    } else {
      listData.value = [];
    }
  });

  watchEffect(() => {
    if (
      integrationCallbackStatuses.value.TYPE_GOOGLE_ANALYTICS &&
      listData.value?.length
    ) {
      gaDynamicParamsEvent(`View Search`, {
        ecomm_prodid: listData.value.map((item) => item.default_sku),
        ecomm_pagetype: "searchresults",
        ecomm_totalvalue: "",
        ecomm_category: "",
      });
    }
  });

  const getResult = handleSubmit(async () => {
    if (timeout) {
      clearTimeout(timeout);
    }
    loading.value = true;
    try {
      const { data } = await $api.search.getSearchSuggestions(
        requestParams.value,
      );
      if (data) {
        listData.value = data.suggestions.products.slice(0, count);
        if (typeof emit === "function") {
          emit("setListDataLength", listData.value.length);
        }
      }
    } catch (error) {
      listData.value = [];
      console.log("error - ", error);
    } finally {
      loading.value = false;
    }
  });

  function goToSearchPage() {
    if (!search.value) {
      return;
    }
    if (typeof emit === "function") {
      emit("goToSearchPage", search.value);
    }

    search.value = "";
  }

  return {
    search,
    errorMessage,
    goToSearchPage,
    count,
    loading,
    listData,
    getResult,
  };
};
